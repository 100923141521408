import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '#app/utils/misc.tsx';

const buttonVariants = cva(
  'inline-flex items-center justify-center text-sm font-medium ring-offset-background transition-colors outline-none focus-visible:ring-1 focus-within:ring-1 ring-ring ring-offset-1 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        basic: '',
        default: 'bg-primary text-white hover:bg-primary-510',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/80',
        outline: 'border border-neutral text-white hover:bg-white/10',
        secondary:
          'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'bg-white/10 text-white hover:bg-transparent',
        link: 'text-white text-base font-normal hover:underline underline-offset-2 justify-start sm:justify-center',
        link2: 'text-white text-sm hover:no-underline underline-offset-2',
        icon: '',
      },
      size: {
        auto: 'p-0 h-auto',
        default: 'h-11 px-4 py-2',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
